<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-form class="multi-col-validation">
      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="12"
          >
            <v-select
              v-model="optionsLocal.languages"
              outlined
              dense
              label="Prospects List"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="optionsLocal.birthday"
              outlined
              dense
              label="Start Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="optionsLocal.birthday"
              outlined
              dense
              label="End Date"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
          >
            <v-select
              v-model="optionsLocal.languages"
              outlined
              dense
              label="Choose Message"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
          >
            <p class="text--primary mt-n3 mb-2">
              Messaging Means
            </p>
            <v-radio-group
              v-model="optionsLocal.gender"
              row
              class="mt-0"
              hide-details
            >
              <v-radio
                value="Email"
                label="Email"
              >
              </v-radio>
              <v-radio
                value="Whatsapp"
                label="Whatsapp"
              >
              </v-radio>
              <v-radio
                value="SMS"
                label="SMS"
              >
              </v-radio>
              <v-radio
                value="in_app_notify"
                label="In-App Notification"
              >
              </v-radio>
              <v-radio
                value="webpush"
                label="Webpush Notification"
              >
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-center">
        <v-btn
          color="primary"
          class="me-3 mt-3"
        >
          Submit
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const optionsLocal = ref(JSON.parse(JSON.stringify(props.informationData)))

    const resetForm = () => {
      optionsLocal.value = JSON.parse(JSON.stringify(props.informationData))
    }

    return { optionsLocal, resetForm }
  },
}
</script>
